import * as React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { linkRegex, hasHash } from "../../helpers"

const Button = ({ to, title, className, children }) => {
  const regex = linkRegex()
  const btnClasses = classNames({
    "my-2 inline-block text-sm text-white opacity-70 cursor-pointer lg:my-1 hover:text-blue hover:opacity-100": true,
    [className]: className,
  })

  return (
    <React.Fragment>
      {hasHash(to) && (
        <AnchorLink to={to} className={btnClasses}>
          {title && title}
          {!title && children}
        </AnchorLink>
      )}

      {regex.test(to) && !hasHash(to) && (
        <a href={to} className={btnClasses}>
          {title && title}
          {!title && children}
        </a>
      )}

      {!regex.test(to) && !hasHash(to) && (
        <Link to={to} className={btnClasses}>
          {title && title}
          {!title && children}
        </Link>
      )}
    </React.Fragment>
  )
}

export default Button
