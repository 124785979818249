import * as React from "react"
import { Link } from "gatsby"
import ThemeContext from "../../shared/theme-context"
import classNames from "classnames"
import { linkRegex } from "../../helpers"
import DownArrow from '../../svg/menu-down-arrow.svg';
import UpArrow from '../../svg/menu-up-arrow.svg';

const NavItem = ({ title, url, subMenu }) => {
  const theme = React.useContext(ThemeContext)

  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false)

  const regex = linkRegex()

  const linkClass = classNames({
    "font-bold lg:text-sm lg:py-3 xl:text-base": true,
    "text-white hover:text-blue": theme === "dark",
    "text-gray-dim hover:text-blue": theme === "light",
    "!text-yellow": title === "Schedule Demo",
    [getLinkClass(url)]: true,
  })

  const subLinkClass = classNames({
    "px-4 py-2 font-bold block": true,
    "hover:text-blue hover:bg-blue hover:!text-white": theme === "dark",
    "text-gray-dim hover:bg-blue hover:text-white": theme === "light",
    "!text-yellow": title === "Schedule Demo",
  })

  const itemClass = classNames({
    "group border-b py-3 lg:py-0 lg:border-none border-white/10": true,
  })

  return (
    <div role="button" tabIndex={0} className='menu-item' onClick={() => setIsSubMenuOpen(!isSubMenuOpen)} onKeyDown={() => {}}>
    <li className={itemClass}>
      {regex.test(url) && (
        <a href={url} className={linkClass}>
          {title}
        </a>
      )}

      {!regex.test(url) && (
        <Link to={url} className={`${linkClass} menu-item-title white-arrow`} onClick={(e) => e.stopPropagation()} activeClassName="!text-blue blue-arrow">
          {title}
          {subMenu && 
            (isSubMenuOpen ? 
              <UpArrow className='sub-menu-arrow' style={{ height: '16px', width: '16px' }}/> 
              : 
              <DownArrow  className='sub-menu-arrow' style={{ height: '16px', width: '16px'}}/>
            )
          }
        </Link>

      )}

      

      {subMenu && (
        <ul className={`sub-menu top-[calc(100%)] bg-white shadow`} style={{ display: isSubMenuOpen ?  'block' : '' }}>
          {subMenu.map((item, index) => (
            <li key={index}>
              {regex.test(item.url) && (
                <a href={item.url} className={subLinkClass}>
                  {item.title}
                </a>
              )}

              {!regex.test(item.url) && (
                <Link
                  to={item.url}
                  className={subLinkClass}
                  activeClassName="!text-blue">
                  {item.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
    </div>
  )
}

export default NavItem


const getLinkClass = (url) => {
  switch (url) {
    case "/mobile-testing":
      return "mobile-testing";
    case "/company":
      return "company";
    default:
      return "";
  }
}
