import React from "react"
import classHelper from "classnames"

import LogoEl4 from "../../svg/logo-el-4.svg"
import Shape from "../../svg/shape-up.svg"

const ShapeUp = ({ x, classNames, children }) => {
  const mainClasses = classHelper({
    "relative bg-gradient-to-b from-blue-royal via-black to-black pt-[calc(calc(calc(100vw-0px)*0.05)-1rem)] lg:pt-[calc(calc(calc(100vw-0px)*0.05)+4rem)] 2xl:pt-[calc(calc(calc(100vw-0px)*0.05))]": true,
    [classNames]: classNames,
  })

  const shapeClasses = classHelper({
    "absolute left-0 top-0 w-full h-auto": true,
    "transform -scale-x-1": x === "left",
  })

  return (
    <div className={mainClasses}>
      <div className="relative z-10">{children}</div>

      <div className="absolute left-0 top-0 w-full h-full overflow-hidden">
        <div className="shape-up-first-shadow absolute opacity-60"></div>
        <div className="shape-up-second-shadow absolute opacity-60"></div>
      </div>

      <Shape className={shapeClasses} />

      <LogoEl4
        className={`absolute top-0 w-1/5 h-auto lg:top-4 lg:w-1/6 xl:top-6 max-w-sm ${
          x === "left" ? "left-0" : "right-0"
        }`}
      />
    </div>
  )
}

export default ShapeUp
