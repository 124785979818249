import * as React from "react"
import classNames from "classnames"
import { Row, Col } from "./grid"

const Badges = ({ color, size, full, mobile, className }) => {
  const wrapperClass = classNames({
    "flex flex-wrap": true,
    "sm:justify-center": full,
    "justify-center": !full,

    [className]: className,
  })

  return (
    <div className={wrapperClass}>
        <Row className='gap-2.5'>
          <Col>
          <img src="https://cdn.drata.com/badge/iso27001-light.png" height="80" width="80" alt="iso"/>
          </Col>
          <Col>
          <img src="https://cdn.drata.com/badge/soc2-light.png" height="80" width="80" alt="soc2"/>
          </Col>
      </Row>
    </div>
  )
}

export default Badges
