import * as React from "react"
import { Link } from "gatsby"
import { ThemeProvider } from "../shared/theme-context"
import classNames from "classnames"
import Nav from "./nav"
import Title from "./ui/title"
import NavToggler from "./ui/nav-toggler"
import PostProgress from "./ui/post-progress"
import Logo from "../svg/logo.svg"

const Header = ({ postTitle }) => {
  const [isNavOpen, setisNavOpen] = React.useState(false)

  const headerClass = classNames({
    "fixed top-0 left-0 z-50 w-full": true,
  })

  return (
    <header className={headerClass}>
      <div className="relative z-999 py-3 bg-black lg:py-4">
        <div className="container flex items-center">
          <Link to="/">
            <span className="sr-only">
              <Title />
            </span>

            <Logo className="w-auto h-6" />
          </Link>

          <ThemeProvider value="dark">
            <Nav isOpen={isNavOpen} />
          </ThemeProvider>

          <NavToggler
            onClick={() => setisNavOpen(!isNavOpen)}
            isOpen={isNavOpen}
          />
        </div>
      </div>

      {postTitle && <PostProgress title={postTitle} />}
    </header>
  )
}

export default Header
