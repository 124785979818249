import * as React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import { linkRegex } from "../../helpers"

const Button = ({ to, color, size, label, className, children }) => {
  const regex = linkRegex()
  const btnClasses = classNames({
    btn: true,
    "btn-pink": color === "pink",
    "btn-green": color === "green",
    "btn-blue": color === "blue" || !color,
    "btn-blue-pacific": color === "blue-pacific",
    "btn-black": color === "black",
    "link-pink": color === "link-pink",
    "btn-outlined-pink": color === "outlined-pink",
    "btn-outlined-blue": color === "outlined-blue",
    "btn-outlined-blue-pacific": color === "outlined-blue-pacific",
    "btn-sm": size === "sm",
    "btn-xs": size === "xs",
    [className]: className,
  })

  return (
    <React.Fragment>
      {regex.test(to) && (
        <a href={to} className={btnClasses}>
          {label && label}
          {!label && children}
        </a>
      )}

      {!regex.test(to) && (
        <Link to={to} className={btnClasses}>
          {label && label}
          {!label && children}
        </Link>
      )}
    </React.Fragment>
  )
}

export default Button
