import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import ExternalLink from "./external-link"
import Github from "../../svg/github.svg"
import Twitter from "../../svg/twitter.svg"
import LinkedIn from "../../svg/linkedin.svg"

const Social = ({ color, size, full, mobile, className }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              github
              linkedin
            }
          }
        }
      }
    `
  )

  const wrapperClass = classNames({
    "flex flex-wrap": true,
    "sm:justify-center": full,
    "justify-center": !full,
    [className]: className,
  })

  const itemClass = classNames({
    "my-3": full,
    "w-full": mobile,
    "w-1/2 sm:px-4 sm:w-auto lg:text-center": full && !mobile,
    "px-3": !full,
  })

  const iconClass = classNames({
    "inline-block w-auto h-8 transition-all duration-100": true,
    "h-8 lg:h-7": size === "sm",
    "h-6": size === "md",
    "h-10": size === "lg",
    "text-white hover:text-blue": color === "white",
    "text-white opacity-70 hover:text-blue hover:opacity-100": color === "gray",
  })

  return (
    <div className={wrapperClass}>
      <div className={itemClass}>
        <ExternalLink
          to={site.siteMetadata?.social?.twitter}
          size={size}
          color={color}
          full={full}
          label="Twitter"
          attrs={{ "aria-label": "twitter", title: "Join our Twitter page" }}
          icon={<Twitter className={iconClass} />}
        />
      </div>

      <div className={itemClass}>
        <ExternalLink
          to={site.siteMetadata?.social?.linkedin}
          size={size}
          color={color}
          full={full}
          label="LinkedIn"
          attrs={{ "aria-label": "linkedin", title: "Join our LinkedIn page" }}
          icon={<LinkedIn className={iconClass} />}
        />
      </div>

      <div className={itemClass}>
        <ExternalLink
          to={site.siteMetadata?.social?.github}
          size={size}
          color={color}
          full={full}
          label="GitHub"
          attrs={{ "aria-label": "github", title: "Follow us on GitHub" }}
          icon={<Github className={iconClass} />}
        />
      </div>
    </div>
  )
}

Social.defaultProps = {
  full: false,
}

export default Social
