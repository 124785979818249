import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import NavItem from "./ui/nav-item"
import Button from "./ui/button"

const Nav = ({ isOpen }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            login
            signup
            menuLinks {
              title
              url
              subMenu {
                title
                url
              }
            }
          }
        }
      }
    `
  )

  const navClass = classNames({
    "absolute z-998 -right-full top-0 w-4/6 h-screen pt-14 pb-16 bg-black overflow-y-scroll transition-right duration-300 in-expo shadow-2xl": true,
    "lg:flex-1 lg:items-center lg:ml-16 2xl:ml-20 lg:flex lg:relative lg:right-auto lg:top-auto lg:w-auto lg:h-auto lg:pt-0 lg:pb-0 lg:bg-none lg:bg-transparent lg:overflow-y-visible lg:shadow-none": true,
    "!right-0": isOpen,
  })

  return (
    <nav className={navClass}>
      <ul className="px-4 text-lg lg:flex lg:h-auto lg:mx-auto lg:space-x-7 lg:space-y-0 lg:text-base">
        {site.siteMetadata.menuLinks.map((link, index) => (
          <NavItem
            key={index}
            title={link.title}
            url={link.url}
            subMenu={link.subMenu}
          />
        ))}
      </ul>

      <div className="mt-6 ml-auto px-4 space-y-4 lg:mt-0 lg:ml-0 lg:px-0 lg:space-y-0 lg:space-x-4">

        {/* hiding this to leave only login as primary nav button
        <Button
          to={site.siteMetadata.login}
          color="outlined-blue"
          size="sm"
          className="w-full !border-0 !text-white lg:w-auto">
          Log in
        </Button> */}

        <Button
          to={site.siteMetadata.login}
          size="sm"
          className="w-full lg:w-auto">
          Log in
        </Button>
      </div>
    </nav>
  )
}

export default Nav
