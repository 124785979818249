export const linkRegex = () => new RegExp("^https?://")

export const hasHash = str => {
  if (!str) {
    return false
  }

  if (str.indexOf("#") > -1) {
    return true
  } else {
    return false
  }
}

export const getRandom = (arr, num = 1) => {
  const res = []

  for (let i = 0; i < num; ) {
    const random = Math.floor(Math.random() * arr.length)
    if (res.indexOf(arr[random]) !== -1) {
      continue
    }

    res.push(arr[random])
    i++
  }

  return res
}

export const getRelatedPosts = (arr, currentPostId, num = 3) => {
  if (!arr || !currentPostId) {
    return []
  }

  const excludeCurrent = arr.filter(item => item.node.id !== currentPostId)

  if (!excludeCurrent.length) {
    return []
  }

  return getRandom(excludeCurrent, num)
}

export const maxChar = (str, count = 70) => {
  if (!str) {
    return str
  }

  const end = str.length > count ? "..." : ""

  return `${str.slice(0, count)}${end}`
}

export const encode = data => {
  return Object.keys(data)
    .map(key => {
      if (data[key].label) {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key].label)
        )
      } else {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      }
    })
    .join("&")
}

export const encodeWithFiles = data => {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

export const priceFormat = x => {
  if (!x) {
    return
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const errorStyles = {
  marginBottom: "5px",
  borderWidth: "1px 1px 1px 8px",
  borderStyle: "solid",
  borderColor: "#db638f",
  borderImage: "initial",
  background: "rgb(251, 236, 242)",
}

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.425rem 0",
    border: "1px solid #606060",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#606060",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
}

export const truncate = (string, limit, after) => {
  if (!string || !limit) {
    return
  }

  let content = string.trim()

  content = content.split(" ").slice(0, limit)
  content = content.join(" ") + (after ? after : "")

  return content
}
