import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import classNames from "classnames"

import FooterLinkItem from "./ui/footer-link-item"
import { Container, Row, Col } from "./ui/grid"
import Title from "./ui/title"
import Social from "./ui/social"
import Logo from "../svg/logo.svg"
import Badges from "./ui/badges"

const Footer = ({ className }) => {
  const date = new Date()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              title
              url
            }

            footerLinks {
              title
              links {
                title
                url
              }
            }
          }
        }
      }
    `
  )

  const handleFooterLists = e => {
    e.target.focus()
    e.target.classList.toggle("active")
    e.target.nextSibling.classList.toggle("!slide-transition--active")
    e.target.nextSibling.classList.toggle("!mb-6")
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      handleFooterLists(e)
    }
  }

  const footerClasses = classNames({
    "pt-12 bg-black": true,
    [className]: className,
  })

  return (
    <footer className={footerClasses}>
      <Container>
      <Badges className="mb-16 mt-0 pt-0"/>
        <Row>
          <Col size="mb-8 w-full lg:w-1/5">
            <Link to="/" className="inline-block">
              <span className="sr-only">
                <Title />
              </span>

              <Logo className="h-5 w-auto" />
            </Link>
          </Col>

          {site.siteMetadata.footerLinks.map(item => item.title ?
            <Col key={item.title} size="w-full lg:w-1/5">
              <div className="w-full">
                <div
                  role="button"
                  className="footer-list-title my-4 font-bold text-white rounded-xl lg:mt-0 focus:outline-none focus:bg-black focus:bg-opacity-10 focus:shadow-outline-footer lg:pointer-events-none lg:focus:bg-none lg:focus:shadow-none"
                  onClick={e => handleFooterLists(e)}
                  onKeyDown={e => handleKeyDown(e)}
                  tabIndex={0}>
                  {item.title}
                </div>
                <ul className="footer-list slide-transition max-h-0 m-0 p-0 list-none overflow-hidden lg:max-h-full">
                  {item.links.map((link, i) => (
                    <li key={i}>
                      <FooterLinkItem to={link.url} title={link.title} />
                    </li>
                  ))}
                </ul>     
              </div>
            </Col>
            : 
            <> 
              {item.links.map((link, i) => (
                <Link to={link.url} key={i}></Link>))
              }
            </>
          )}
        </Row>
      </Container>

      <div className="mt-12 h-px w-full bg-gradient-to-r from-black via-blue/40 to-black"></div>

      <div className="py-8 bg-black">
        <Container>
          <div className="flex flex-wrap items-center">
            <small className="w-full space-x-6 text-white/50 text-center lg:w-auto lg:text-left lg:mr-0">
              <span>
                &copy; <Title /> {date.getFullYear()}.
              </span>

              <a
                href="https://www.loadmill.com/assets/website-terms.pdf"
                className="underline text-sm text-white/50 hover:text-blue/100">
                Terms of use
              </a>
            </small>

            <Social
              size="sm"
              color="white"
              className="mt-10 mx-auto lg:mt-0 lg:mr-0"
            />
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
